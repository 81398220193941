@media (max-width: 768px) {
    .navbar {
        justify-content: space-around !important;
    }

    hr:not([size]) {
        height: 3px;
    }

    .MuiListItemIcon-root {
        min-width: 42px !important;
    }

    .MuiDrawer-paper img {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .MuiDrawer-paper .MuiListItemText-primary {
        padding-top: 10px;
        padding-bottom: 10px;
        font: normal normal 500 17px/0px Poppins;
        color: #000000 !important;
    }

    .heading-3 {
        margin-bottom: 5px;
        font: normal normal 600 20px/40px Poppins;
    }

    .heading-3::after {
        width: 68px;
    }

    .heading-4 {
        font: normal normal 600 24px/40px Poppins;
    }

    #intro {
        padding-top: 46px;
        padding-bottom: 46px;
    }

    #intro .content {
        padding: 24px;
    }

    #intro .content h1, #intro .content h2 {
        font: normal normal 500 14px/26px Poppins;
    }

    #intro .content p {
        margin-top: 20px;
        margin-bottom: 18px;
        font: normal normal 600 10px/0px Poppins;
    }

    #intro .slick-dots {
        text-align: left;
        position: unset;
    }

    #intro .slick-dots li button {
        height: 5px;
        width: 5px;
    }

    .entry {
        padding-top: 38px;
    }

    .before span {
        margin-top: -60px;
        margin-left: 7px;
        font: normal normal bold 44px Poppins;
    }

    h2 {
        margin-top: 14px;
        padding-bottom: unset;
        font: normal normal bold 26px/38px Poppins;
    }

    p {
        font: normal normal normal 14px/28px Poppins;
    }

    #about a, .card-offer a {
        font: normal normal 600 13px/19px Poppins;
    }

    .b-growth {
        margin-top: 2rem;
    }

    .breadcrumbs {
        padding: 32px 0;
    }

    .breadcrumbs h1 {
        margin-top: 20px;
        font: normal normal bold 24px/0px Poppins;
    }

    .breadcrumbs ul li a, .breadcrumbs ul li+li, .breadcrumbs ul li+li::before {
        font: normal normal 500 16px/0px Poppins;
    }

    .breadcrumbs ul li+li::before {
        margin-top: 15px;
    }

    section {
        padding-top: unset;
    }

    .heading-2 {
        font: normal normal bold 20px/29px Poppins;
    }

    .card-mission-1, .card-mission-2, .card-mission-3 {
        padding: 25px;
        margin-top: 20px;
    }

    .card-offer .info p {
        font: normal normal normal 14px/31px Poppins;
    }

    .counts {
        margin-bottom: unset;
    }

    .counts h3 {
        font: normal normal 500 16px/30px Proxima Nova;
    }

    .counts span {
        font: normal normal bold 38px/90px Poppins;
    }

    .counts .number {
        margin-left: 16%;
    }

    .card-project-details {
        padding: 1px 16px;
    }

    .project-details .slick-dots li {
        width: 62px;
    }

    .project-details .slick-thumb li.slick-active img {
        padding: 4px;
    }

    .card-project-details p {
        font: normal normal normal 16px/32px Poppins;
    }

    .bg-design-1 {
        padding-top: unset;
        background: unset;
    }

    .testimonials, .clients {
        margin-top: unset;
    }

    .card-project-details h4 {
        margin-top: 14px;
        font: normal normal bold 18px/24px Poppins;
    }

    .card-project-details h3 {
        font: normal normal 500 14px/25px Poppins;
    }

    .contact-info .icon:first-child {
        margin-top: unset;
    }

    .contact-form {
        margin-top: 20px;
        padding: 22px;
    }

    p.stroke {
        font-size: 44px;
        margin-top: -60px;
    }

    .testimonials .quote {
        margin-top: -21% !important;
    }

    .card-testimonials p {
        font: normal normal normal 14px/28px Poppins;
    }

    .card-testimonials h4 {
        font: normal normal 600 14px/15px Poppins;
    }

    .slick-dots li {
        width: 9px;
    }

    .cmt-1 {
        margin-top: 62px;
    }

    .cmb-1 {
        margin-bottom: 28px;
    }

    .contact-info-2 {
        width: 100%;
    }

    .contact-info-2 h4 {
        margin-top: 2rem;
        font-size: 20px;
    }

    .contact-info-2 .icon svg {
        font-size: 68px;
    }

    .card-sidemenu {
        padding: 18px;
        margin-bottom: 20px;
    }

    .card-sidemenu .MuiTab-wrapper {
        font: normal normal normal 16px/0px Poppins;
    }

    .service-detail p {
        font: normal normal normal 16px/30px Poppins;
    }

    #projects .PrivateTabIndicator-colorSecondary-8, #projects .jss7 {
        background-color: unset !important;
    }

    #projects .MuiTabs-flexContainer {
        justify-content: unset;
    }

    #footer .content {
        padding-top: 38px;
        background: unset;
        margin-bottom: 70px;
    }

    #footer .content a, #footer .contact p {
        font: normal normal normal 14px/20px Poppins;
    }

    #footer .contact p {
        padding-left: 30px;
    }

    #footer .contact svg {
        font-size: 18px;
    }

    #footer .copyright p, #footer .copyright p a {
        font: normal normal normal 13px/22px Poppins;
    }

    .before.type-2 img.logo2 {
        max-width: 20%;
    }
}