@font-face {
  font-family: Proxima Nova;
  src: url(../fonts/proxima-nova-font.otf);
}

/* General */

:root {
  --accent: #29265d;
  --secondary: #f60803;
  /* --secondary: #97acd1; */
  --white: #ffffff;
  --blue: #97acd1;
}

section {
  padding-top: 40px;
  padding-bottom: 40px;
}

a {
  text-decoration: none;
}

h2 {
  font: normal normal bold 36px/55px Poppins;
  color: #000000;
  margin-top: 30px;
  text-transform: capitalize;
  opacity: 0.8;
  padding-bottom: 40px;
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scaleX(-1);
}

.heading-2 {
  font: normal normal bold 30px/46px Poppins;
  color: #000000;
  text-transform: capitalize;
  opacity: 0.8;
}

.heading-2::after {
  content: "";
  border-radius: 2px;
  display: block;
  height: 3px;
  width: 100px;
  background: var(--accent);
}

.heading-3 {
  margin-top: unset;
  margin-bottom: 15px;
  padding-bottom: unset;
  font: normal normal 600 24px/40px Poppins;
  color: #000000;
  text-transform: capitalize;
  opacity: 0.8;
}

.heading-3::after {
  content: "";
  border-radius: 2px;
  display: block;
  height: 3px;
  width: 100px;
  background: var(--accent);
}

.heading-4 {
  margin-top: 30px;
  margin-bottom: 20px;
  font: normal normal 600 36px/40px Poppins;
  color: #000000;
  text-transform: capitalize;
  opacity: 0.8;
}

.heading-4::after,
.heading-5::after {
  content: "";
  border-radius: 2px;
  display: block;
  height: 3px;
  width: 50px;
  background: var(--accent);
}

.heading-5 {
  font: normal normal 600 24px/35px Poppins;
  color: #000000;
  text-transform: capitalize;
  opacity: 0.8;
}

.mb-unset {
  padding-bottom: unset;
}

h2.text-center {
  margin-top: -22px;
}

p {
  font: normal normal normal 16px/35px Poppins;
  color: #4d4d4d;
  /* opacity: 0.7; */
}

p img.logo {
  margin-top: -25px;
}

.slick-track {
  width: 100%;
}

#intro .slick-dots {
  width: unset;
}

#intro .slick-dots li.slick-active button:before,
.slick-dots li button:before {
  all: unset;
}

#intro .slick-dots li {
  width: unset;
  height: unset;
}

#intro .slick-dots li button {
  background-color: #fff;
  height: 7px;
  padding: unset;
  width: 8px;
  border-radius: 10px;
  transition: width 0.5s;
}

#intro .slick-dots li.slick-active button {
  width: 28px;
}

#intro .slick-dots li.slick-active {
  margin-right: 10px;
  margin-left: 10px;
}

.entry {
  padding-top: 80px;
}
.ft-22 {
  font-size: 22px;
}

.breadcrumbs {
  text-align: center;
  padding: 96px 0;
  min-height: 100%;
  background: linear-gradient(
      111deg,
      rgb(34 32 30 / 60%) 60%,
      rgb(34 32 30 / 60%) 60%,
      rgb(34 32 30 / 60%) 60%
    ),
    url(../images/bg-breadcumb.png);
  background-size: cover;
}

.breadcrumbs h1 {
  font: normal normal bold 36px/40px Poppins;
  color: #ffffff;
}

.breadcrumbs ul {
  padding-left: unset;
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
}

.breadcrumbs ul li a {
  text-decoration: none;
  font: normal normal 500 20px/40px Poppins;
  color: #ffffff;
}

.breadcrumbs ul li a:hover {
  color: #03abee !important;
}

.breadcrumbs ul li + li {
  padding-left: 10px;
  font: normal normal 500 20px/40px Poppins;
  color: #ffffff;
}

.breadcrumbs ul li + li::before {
  display: inline-block;
  padding-right: 10px;
  content: "/";
  font: normal normal 500 20px/40px Poppins;
  color: #ffffff;
}

.btn-style-1 {
  border: none;
  padding: 10px 20px 10px 20px !important;
  background-color: var(--secondary) !important;
  border-radius: 6px;
  font: normal normal bold 14px/17px Proxima Nova;
  color: #ffffff !important;
  opacity: 0.8;
}

.btn-style-1 svg {
  font: normal normal bold 16px/17px Proxima Nova;
}

p.stroke {
  text-align: center;
  margin-top: -87px;
  font: normal normal bold 80px Poppins;
  color: #00000000;
  text-transform: uppercase;
  -webkit-text-fill-color: white;
  /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #3024ffb8;
  opacity: 0.2;
}

.btn-call svg {
  font-size: 18px;
}

#about h2 {
  padding-bottom: unset;
}

#about a,
.card-offer a,
#build a {
  text-decoration: none;
  display: flex;
  font: normal normal 600 14px/21px Poppins;
  color: #000000;
  text-transform: capitalize;
  opacity: 0.8;
}

.card-offer a.inner {
  display: unset;
}

#about a svg,
.card-offer a svg,
#build a svg {
  margin-left: 5px;
  font: normal normal 600 18px/21px Poppins;
  color: var(--accent);
}

.before h3 {
  padding-left: 15px;
  font: normal normal 600 18px/40px Poppins;
  color: var(--accent);
  text-transform: capitalize;
  opacity: 1;
  visibility: hidden;
}

.before:before {
  /* content: ""; */
  position: absolute;
  padding: 2px;
  height: 40px;
  background: var(--accent);
}

.before.type-2:before {
  all: unset;
}

.before span {
  margin-top: -87px;
  margin-left: -70px;
  font: normal normal bold 80px Poppins;
  color: #00000000;
  text-transform: uppercase;
  -webkit-text-fill-color: white;
  /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #3024ffb8;
  opacity: 0.2;
  position: absolute;
  z-index: -999;
}

.before-2 {
  text-align: center;
}

.before-2 h3 {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  font: normal normal 600 18px/40px Poppins;
  color: var(--accent);
  text-transform: capitalize;
  opacity: 1;
  border-left: 4px solid var(--accent);
}

.before-2.type-2 h3 {
  border-left: unset;
}

.before-2.type-2 img {
  padding: unset;
}

/* Header */

.navbar {
  padding-bottom: 1rem;
}

.navbar-nav {
  margin-bottom: -26px;
}

.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .nav-item .nav-link {
  margin-left: 20px;
  font: normal normal 500 16px/21px Poppins;
  color: #000000;
  text-transform: uppercase;
  opacity: 0.8;
  font-weight: 600;
}

.navbar-light .navbar-nav .nav-item {
  margin-left: 20px;
}

.navbar-light .navbar-nav .nav-item .dropdown-item {
  font: normal normal 500 14px/21px Poppins;
  color: #000000;
  text-transform: uppercase;
  opacity: 0.8;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff !important;
  background-color: var(--secondary);
}

.navbar-light .navbar-nav .nav-link:first-child {
  margin-left: unset;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  color: var(--secondary);
  border-bottom: 2px solid var(--secondary);
  opacity: 1;
}

/* Intro */
/* 
#intro {
  padding-top: 130px;
  padding-bottom: 130px;
  min-height: 100%;
  background: linear-gradient(111deg, #075880 0%, #3A282821 100%, #39050500 100%), url(../images/bg-hero.png);
  background-size: cover;
} */

#intro .content {
  padding: 80px;
  /* background: #ff00008c 0% 0% no-repeat padding-box; */
  background: #29265dd1 0% 0% no-repeat padding-box;
}

#intro .content h1,
#intro .content h2 {
  margin: unset;
  padding: unset;
  font: normal normal 600 35px/55px Poppins;
  color: #ffffff;
  text-transform: capitalize;
}

#intro .content p {
  margin-top: 50px;
  margin-bottom: 50px;
  font: normal normal 600 14px/0px Poppins;
  color: #ffffff;
}

#intro .content p svg {
  font-size: inherit;
}

#about h5 {
  font: normal normal 600 14px/21px Poppins;
  color: #000000;
  text-transform: capitalize;
  opacity: 0.8;
  margin-right: 7px;
}

#about .before span {
  margin-left: unset;
}

.related-projects .project-single {
  margin-top: 20px;
  padding: unset;
}

#projects .MuiTabs-flexContainer {
  justify-content: center;
}

#projects .PrivateTabIndicator-colorSecondary-8,
#projects .jss7 {
  background-color: unset !important;
}

#projects .MuiButtonBase-root.Mui-selected {
  border: 2px solid var(--accent);
  border-radius: 55px;
}

#projects .MuiTab-wrapper {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  text-transform: capitalize;
  opacity: 0.8;
}

#projects .Mui-selected .MuiTab-wrapper {
  color: var(--accent);
  opacity: 1;
}

#projects .MuiTab-textColorInherit {
  opacity: unset;
}

.project-single {
  padding: 15px;
}

.project-single:hover h4 {
  display: block;
}

.project-single h4 {
  display: none;
  position: relative;
  margin-top: -56px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: unset;
  text-align: center;
  font: normal normal 600 18px/27px Poppins;
  color: #ffffff;
  background-color: #000000b7;
  text-transform: capitalize;
}

.mission-row {
  overflow: hidden;
  position: relative;
}

.card-mission-1,
.card-mission-2,
.card-mission-3 {
  text-align: center;
  padding: 45px 25px;
  background: rgba(151, 172, 209, 0.2) 0% 0% no-repeat padding-box;
  /* background: #F9F9FD 0% 0% no-repeat padding-box; */
  border-radius: 10px;
  transition: all 0.5s;
  height: 90%;
}

.card-mission-1:hover,
.card-mission-2:hover,
.card-mission-3:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 60px #586eb529;
}

.card-mission-1 .icon-box,
.card-mission-2 .icon-box,
.card-mission-3 .icon-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 12px #00000029;
  border-radius: 16px;
  margin-bottom: 30px;
  transition: all 0.5s;
}

.card-mission-1:hover .icon-box,
.card-mission-2:hover .icon-box,
.card-mission-3:hover .icon-box {
  background: var(--accent) 0% 0% no-repeat padding-box;
  box-shadow: none;
  border-radius: 16px;
}

.card-sidemenu {
  background: #f9f9fd 0% 0% no-repeat padding-box;
  border-radius: 9px;
  padding: 30px;
}

.card-sidemenu .MuiButtonBase-root {
  padding-left: unset;
}

.card-sidemenu .MuiListItem-button {
  align-items: baseline;
  font-size: 16px;
  font-weight: 400;
  font-family: Poppins;
  color: #000000;
  text-transform: capitalize;
  opacity: 0.8;
}

.card-sidemenu .MuiButtonBase-root {
  border-bottom: 1px solid #d0d0d0;
}

.card-sidemenu .MuiButtonBase-root:last-child {
  border-bottom: unset;
}

.card-sidemenu .Mui-selected {
  background: unset !important;
  color: var(--accent);
  opacity: 1;
}

.card-sidemenu .MuiButtonBase-root:hover {
  background: unset !important;
}

.card-project-details {
  background-color: #f8f8fd;
  padding: 1px 40px;
}

.card-project-details h2 {
  padding-bottom: 12px;
}

.card-project-details p {
  font: normal normal normal 16px/44px Poppins;
  color: #000000;
  opacity: 0.8;
}

.card-project-details .data {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.card-project-details h3 {
  font: normal normal 500 16px/25px Poppins;
  color: #000000;
  text-transform: capitalize;
  opacity: 0.8;
}

.card-project-details h4 {
  font: normal normal bold 18px/26px Poppins;
  color: #000000;
  text-transform: capitalize;
  opacity: 0.8;
}

.project-details .slick-dots li {
  width: 200px;
}

.project-details .slick-thumb li.slick-active img {
  padding: 10px;
  border: 1px solid #c72323;
}

.project-details .slick-dots {
  position: unset;
  margin-top: 20px;
}

.service-detail p {
  font: normal normal normal 16px/38px Poppins;
  color: #000000;
  opacity: 0.7;
}

.mission table {
  margin: auto;
}

.or-1,
.or-2,
.or-3 {
  display: table;
}

.hover-1,
.hover-2,
.hover-3 {
  display: none;
}

.card-mission-1:hover .or-1 {
  display: none;
}

.card-mission-1:hover .hover-1 {
  display: block;
}

.card-mission-2:hover .or-2 {
  display: none;
}

.card-mission-2:hover .hover-2 {
  display: block;
}

.card-mission-3:hover .or-3 {
  display: none;
}

.card-mission-3:hover .hover-3 {
  display: block;
}

.card-mission h3 {
  font: normal normal 600 20px/40px Poppins;
  color: #000000;
  text-transform: capitalize;
}

.card-mission p {
  font: normal normal normal 16px/35px Poppins;
  color: #000000;
  opacity: 0.7;
}

.card-offer {
  text-align: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #297ced29;
  margin-bottom: 3rem;
}

.card-offer .info {
  padding: 20px;
}

.card-offer .info a h3,
.card-offer .info h3 {
  font: normal normal 600 17px/30px Poppins;
  color: #000000;
  text-transform: capitalize;
  margin-left: auto;
  margin-right: auto;
}

.card-offer .info a h3:hover,
.card-offer .info h3:hover {
  color: var(--accent);
}

.ongoing {
  position: relative;
  background-color: var(--accent);
}

.ongoing:hover img {
  opacity: 0.3;
}

.ongoing:hover .middle {
  opacity: 1;
}

.ongoing .text {
  color: white;
  font: normal normal bold 36px/55px Poppins;
  text-transform: capitalize;
  padding: 16px 32px;
}

.ongoing img {
  opacity: 1;
  display: block;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.ongoing .middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.card-offer .info a h3::after {
  content: "";
  border-radius: 2px;
  display: block;
  height: 3px;
  width: 100%;
  background: var(--accent);
}

.card-offer .info p {
  font: normal normal normal 16px/36px Poppins;
  color: #000000;
  opacity: 0.7;
}

.card-testimonials {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 25px #586eb529;
  border-radius: 10px;
  margin: 21px;
  height: 90%;
  padding: 50px 20px 28px 20px;
}

.testi-row {
  overflow: hidden;
  position: relative;
}

.testimonials,
.clients {
  margin-top: 3rem;
}

.testimonials .quote {
  margin-top: -19% !important;
  float: right;
  margin-right: 12px;
}

.card-testimonials p {
  text-align: center;
  font: normal normal normal 16px/35px Poppins;
  color: #000000;
  opacity: 0.7;
}

.card-testimonials h4 {
  text-align: center;
  font: normal normal 600 20px/40px Poppins;
  color: #000000;
  text-transform: capitalize;
  opacity: 0.8;
}

.testimonials .slick-dots li button {
  width: 14px;
  height: 14px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--accent);
  border-radius: 12px;
}

.testimonials .slick-dots li.slick-active button {
  background-color: var(--accent);
}

.counts {
  text-align: center;
  margin-bottom: 50px;
}

.counts span {
  font: normal normal bold 61px/91px Poppins;
  color: var(--accent);
}

.counts .number {
  padding-top: 42px;
  padding-left: 10px;
  background: transparent url(../images/bg-counts.svg) 0 0 no-repeat padding-box;
  background-position: 25%;
  margin-left: 25%;
}

.counts p.suffix {
  font: normal normal 500 32px/48px Poppins;
  color: var(--accent);
}

.counts h3 {
  font: normal normal 600 24px/35px Proxima Nova;
  color: #000000;
  opacity: 0.8;
}

.clients img {
  padding-left: 50px;
  padding-right: 50px;
}

.contact-info-2 {
  margin-left: auto;
  margin-right: auto;
}

.contact-info-2 a {
  font: normal normal medium 22px/40px Poppins;
  color: #000000;
  opacity: 0.7;
}

.contact-info-2 a span {
  font: normal normal 500 14px/0 Poppins;
}

.contact-info-2 .icon {
  color: var(--accent);
}

.contact-info-2 .icon svg {
  font-size: 76px;
  padding: 20px;
  border: 1px solid black;
  border-radius: 50px;
  margin-bottom: 20px;
}

.contact-info-2 .icon svg path {
  padding: 20px;
}

.bg-design-1 {
  padding-top: 65px;
  padding-bottom: 10px;
  background: transparent url(../images/bg-design-1.png) 0 0 no-repeat
    padding-box;
  background-position: bottom right;
}

.cmt-1 {
  margin-top: 140px;
}

.cmb-1 {
  margin-bottom: 70px;
}

.contact-form {
  padding: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 30px #53749a29;
}

.contact-form h2 {
  margin-top: 14px;
  padding-bottom: 20px;
}

.contact-form .form-control,
.contact-form button {
  margin-top: 35px;
}

.contact-form .MuiInputLabel-shrink {
  padding-left: 7px !important;
  padding-right: 7px !important;
  background-color: #fff !important;
  color: #03abee !important;
}

.mt-unset {
  margin-top: unset !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #03abee !important;
}

.contact-info {
  text-align: center;
}

.contact-info svg {
  margin: 0 10px 12px 10px;
  color: var(--accent);
}

.contact-info h4 {
  font: normal normal 600 18px/25px Poppins;
  color: #131d3b;
  opacity: 0.8;
}

.contact-info .icon {
  margin-top: 40px;
}

.contact-info p,
.contact-info p a {
  font: normal normal normal 16px/21px Poppins;
  color: #000000;
  opacity: 0.8;
}

.contact-info .icon:before,
.contact-info .icon:after {
  display: inline-block;
  content: "";
  border-top: 1px solid var(--accent);
  width: 48px;
  transform: translateY(-0.6rem);
}

.pri-policy h2 {
  padding-bottom: 12px;
}

.sitemap ul {
  list-style: none;
  padding-left: unset;
}

.sitemap ul li {
  padding-top: 5px;
  padding-bottom: 5px;
}

.sitemap ul li a {
  color: #000000;
  font: normal normal normal 16px/32px Poppins;
}

.sitemap ul li a:hover {
  color: #ff0000;
}

/* Footer */

#footer {
  /* background-color: #333232; */
  background-color: var(--accent);
}

#footer .content {
  padding-top: 65px;
  padding-bottom: 10px;
  background: transparent url(../images/bg-footer.png) 0 0 no-repeat padding-box;
}

#footer .content p {
  font: normal normal normal 14px/35px Poppins;
  color: #ffffff;
  opacity: 0.9;
}

#footer .content h4,
#footer .content h5 {
  font: normal normal 600 18px/40px Poppins;
  color: #ffffff;
  text-transform: capitalize;
}

#footer .content h5::after {
  margin: 0 0 4px 8px;
  display: inline-block;
  content: "";
  border-top: 2px solid #ff0000;
  width: 32px;
  /* -webkit-transform: translateY(-1rem); */
  /* transform: translateY(-0.3rem); */
}

#footer .content a {
  font: normal normal normal 16px/32px Poppins;
  color: #ffffff;
}

#footer .content ul {
  padding-left: unset;
  list-style: none;
}

#footer .content a {
  text-decoration: none;
}

#footer .contact p {
  margin-top: -3px;
  padding-left: 35px;
  font: normal normal normal 16px/32px Poppins;
  color: #ffffff;
}

#footer .contact svg {
  float: left;
  display: flex;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  opacity: 0.9;
}

#footer hr {
  color: #707070;
  height: 2px;
}

#footer .copyright p,
#footer .copyright p a {
  text-align: center;
  font: normal normal normal 16px/25px Poppins;
  color: #fcfcfc;
  opacity: 0.8;
}

.menu-footer {
  position: fixed;
  font-size: 13px;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: #ffffffd1;
  z-index: 999;
  border-top: 1px solid #ccc;
}

.menu-footer a {
  color: #374957 !important;
}

.menu-footer a.active {
  color: #03abed !important;
}

.menu-footer .b-right {
  border-right: 1px solid #ccc;
}

.react-tel-input .form-control {
  margin-top: 35px !important;
  width: 100% !important;
}


.maintainance {
  margin: auto 0;
  background: url(../images/maintainance.svg) no-repeat;
  background-size: contain;
  height: 100vh;
}